import cn from 'classnames';

import Image from '@/components/base/elements/Image/Image';
import Section from '@/components/base/layouts/Section/Section';
import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import type {HeroProps} from '@/components/base/sections/Hero/types';
import WistiaVideoPlayer from '@/components/shared/WistiaVideoPlayer';
import IncentivesPromoBanner from '@/components/shared/IncentivesPromoBanner/IncentivesPromoBanner';
import {ColorSchemeEnum} from '@/enums';
import useCtasOrSignup from '@/hooks/useCtasOrSignup/useCtasOrSignup';
import {heroBackgroundByTheme} from '@/stylesheets/brochureV2/colorSchemes';
import {twMerge} from '@/stylesheets/twMerge';
import {componentName} from '@/components/base/sections/Hero/types';

export default function HeroFloatingImage({
  className,
  colorScheme,
  ctas,
  headingGroupProps,
  headingHtml,
  images,
  kicker,
  mode = 'light',
  signup: signupData,
  subheadHtml,
  videos,
  isBleedImage,
  displayPromoBanner = false,
  isPillarPage = false,
  ...props
}: HeroProps) {
  const buttonGroupProps = useCtasOrSignup(signupData || {}, ctas);
  const scheme = colorScheme || ColorSchemeEnum.Lavender;
  const darkColorSchemes = [ColorSchemeEnum.Black, ColorSchemeEnum.Kale];

  return (
    <Section
      className={twMerge(
        cn('relative pt-hero-top pb-3xl md:pb-0', {
          'pb-0': isBleedImage,
        }),
        heroBackgroundByTheme[scheme],
        className,
      )}
      errorBoundaryFullWidth={true}
      navbarPadding={true}
      data-component-name={componentName}
      {...props}
    >
      <TwoColumn>
        <TwoColumn.Col1 className="flex flex-col pt-3xl sm:col-span-6 my-auto md:py-3xl gap-y-md">
          <IncentivesPromoBanner
            mode={
              (colorScheme && darkColorSchemes.includes(colorScheme)) ||
              mode === 'dark'
                ? 'light'
                : 'dark'
            }
            className="pb-6"
          />
          <HeadingGroup
            kicker={kicker as string}
            headingAs="h1"
            headingHtml={headingHtml as string}
            subheadHtml={subheadHtml as string}
            mode={mode}
            {...headingGroupProps}
          />
          {buttonGroupProps && (
            <ButtonGroup
              mode={mode}
              {...buttonGroupProps}
              displayPromoBanner={displayPromoBanner}
              isPillarPage={isPillarPage}
            />
          )}
        </TwoColumn.Col1>
        <TwoColumn.Col2
          className={cn('my-auto sm:col-start-1 sm:col-span-8 md:py-3xl', {
            '!py-0 flex-row h-full items-end': isBleedImage,
          })}
        >
          {images && images.length > 0 && (
            <Image className="mx-auto" {...images[0]} />
          )}
          {videos && videos.length > 0 && (
            <WistiaVideoPlayer
              videoId={videos[0].videoId as string}
              {...videos[0].options}
            />
          )}
        </TwoColumn.Col2>
      </TwoColumn>
    </Section>
  );
}
